<template>
    <div class="mx-4 my-4 md:mx-8 md:my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Usuário {{ form.name }}
            </h1>
        </div>
        
        <div class="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
                <div class="px-5 pb-2">
                    <div class="grid grid-cols-12 gap-6">
                        <div class="col-span-12 md:col-span-6">
                            <label for="name" class="block text-sm font-medium">Nome</label>
                            <input v-model="form.name" type="text" name="name" id="name" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div> 
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-3">
                            <label for="email" class="block text-sm font-medium">Telefone Celular</label>
                            <the-mask :mask="['(##) ####-####', '(##) #####-####']" v-model="form.phone" placeholder="(99) 99999-9999" type="text" name="phone" id="phone" autocomplete="off" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" />
                        </div>
                        <div class="col-span-12 md:col-span-5">
                            <label for="email" class="block text-sm font-medium">Email</label>
                            <input v-model="form.email" type="email" name="email" id="name" autocomplete="off" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div v-if="!alterarPassword" class="col-span-12 md:col-span-4">
                            <button @click="alterarPassword = !alterarPassword" type="button" class="inline-flex justify-center py-2 px-6 md:mt-7 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-500">
                                Alterar password
                            </button>
                        </div>
                        <div v-if="alterarPassword" class="col-span-12 md:col-span-4">
                            <label for="password" class="block text-sm font-medium">Password</label>
                            <input v-model="form.password" type="password" name="password" id="password" autocomplete="off" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                    </div>
                    <div v-if="!$route.params.id" class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-12">
                            <div class="mt-2 mb-2">
                                <label class="inline-flex items-center">
                                    <input type="radio" class="form-radio" name="typeUser" v-model="form.typeUser" value="consultor">
                                    <span class="ml-2">Consultor</span>
                                </label>
                                <label class="inline-flex items-center ml-6">
                                    <input type="radio" class="form-radio" name="typeUser" v-model="form.typeUser" value="admin">
                                    <span class="ml-2">Admin</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-5 pb-4 mt-3">
                    <label for="telefone" class="block text-sm font-medium">Foto Perfil</label>
                    <small class="text-xs font-medium">Tamanho recomendado 512x512</small>
                    <div class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-4">
                            <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateFoto">
                            </vue-dropzone>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <img class="w-32 h-32 rounded-full" v-if="this.form.foto && this.form.foto.filename" :src="`${this.url_api}/upload?mimetype=image/jpeg&filename=${this.form.foto.filename}&folder=empresas`"/>
                        </div>
                    </div>
                </div>

                <div class="px-5 pb-4">
                    <label for="telefone" class="block text-sm font-medium">Logo</label>
                    <small class="text-xs font-medium">Tamanho recomendado 400x200, PNG sem fundo</small>
                    <div class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-4">
                            <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateLogo">
                            </vue-dropzone>
                        </div>
                        <div class="col-span-12 md:col-span-4">
                            <img class="h-16" v-if="this.form.logo && this.form.logo.filename" :src="`${this.url_api}/upload?mimetype=image/jpeg&filename=${this.form.logo.filename}&folder=empresas`"/>
                        </div>
                    </div>
                </div>

                <div v-if="form.siteConfig" class="px-5 pb-4">
                    <div class="col-span-12 md:col-span-8">
                        <div class="grid grid-cols-12 gap-3">
                            <div class="col-span-12 md:col-span-6">
                                <label for="arte" class="block text-sm font-medium">Imagem da tela de login</label>
                                <small class="text-xs font-medium">Tamanho recomendado 1920x1080</small>
                                <vue-dropzone ref="doc" id="doc" class="w-full mt-2" :options="dropzoneOptions" v-on:vdropzone-success="update">
                                </vue-dropzone>
                            </div>
                            <div class="col-span-12 md:col-span-6">
                                <img v-if="form.siteConfig.imagem && form.siteConfig.imagem.mimetype" class="border-1 mt-14 h-60 rounded-md" :src="`${url_api}/upload?mimetype=${form.siteConfig.imagem.mimetype}&filename=${form.siteConfig.imagem.filename}&folder=empresas`" />
                            </div>
                        </div>
                    </div>

                    <div class="col-span-12 md:col-span-4">
                        <label for="frase_site" class="block text-sm font-medium mt-2">Texto da página de login</label>
                        <textarea rows="6" v-model="form.siteConfig.texto" type="text" id="frase_site" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder=""/>
                    </div> 
                </div>
                
                <div class="grid grid-cols-2">
                    <div class="px-5 py-3 text-left sm:px-6">
                        <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                        <button @click="logout" type="button" class="mx-2 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                            Logout
                        </button>
                    </div>
                    <div class="px-5 py-3 text-right sm:px-6">
                        <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Salvar
                        </button>
                    </div>
                </div>

                
            </form>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    components: {
        vueDropzone
    },
    data() {
        return {
            route: 'users',
            alterarPassword: this.$route.params.id ? false : true,
            form: {
                name: '',
                email: '',
                password: '',
                typeUser: 'consultor',
                logo: null,
                foto: null,
                siteConfig: null
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste aqui',
                dictRemoveFile: 'Remover',
                acceptedFiles: 'image/*'
            }),
        }
    },
    methods: {
        async save() {
            if(!this.form.name) return this.$vToastify.error("Digite seu nome");
            if(!this.form.email) return this.$vToastify.error("Digite seu email");
            if(!this.form.phone) return this.$vToastify.error("Digite seu telefone celular");

            if(this.form.typeUser === 'consultor'){
                this.form.consultor = true;
                this.form.admin = false;
            } else {
                this.form.consultor = false;
                this.form.admin = true;
            }
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                if(req.data.userLogin && req.data.userLogin._id){
                    await localStorage.setItem('user', JSON.stringify(req.data.userLogin));
                    this.$store.commit('login', req.data.userLogin);
                }
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.push({path: `/`});
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        updateFoto(file, response) {
            this.form.foto = response.file;
        },
        updateLogo(file, response) {
            this.form.logo = response.file;
        },
        async update(file, response) {
            this.form.siteConfig.imagem = response.file;
        },
        async logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('empresa');
            localStorage.removeItem('user');
            this.$store.commit('logout');
            this.$store.commit('setEmpresa', null);
            this.$router.push({path: '/login'});
        }
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            if(req.data.consultor) this.form.typeUser = 'consultor';
            else  this.form.typeUser = 'admin';
        }
    },
}
</script>